import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function ProgressBar({ className, barColor, barBackground, currentProgress }) {
  let progressPercentage = `${currentProgress * 100}%`;
  return (
    <div
      className={className}
      css={css`
        height: 1rem;
        background-color: ${barBackground ? barBackground : 'var(--gray)'};

        @media (max-width: 550px) {
          height: 0.5rem;
        }

        &::after {
          content: '';
          display: block;
          height: 10px;
          background-color: ${barColor ? barColor : 'var(--black)'};
          width: ${progressPercentage ? progressPercentage : '0'};

          @media (max-width: 550px) {
            height: 0.5rem;
          }
        }
      `}
    ></div>
  );
}
