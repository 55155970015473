import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import ProgressBar from './progress-bar';

export default function ProgressBarWrapper({
  className,
  children,
  barColor,
  barBackground,
  progressRef,
}) {
  const [progress, setProgress] = useState(0);
  const [hide, setHide] = useState(true);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [progressRef]);

  const handleScroll = () => {
    const y = window.pageYOffset;

    const currentProgress = (y - progressRef.current.offsetTop) / progressRef.current.offsetHeight;

    if (currentProgress >= 0 && currentProgress <= 1) {
      setProgress(currentProgress);
      setHide(false);
    } else {
      setHide(true);
    }
  };

  return (
    <div
      className={className}
      css={css`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: var(--white);
        filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.2));
        z-index: 1001;
        opacity: ${hide ? 0 : 1};
        pointer-events: ${hide ? 'none' : 'auto'};
        transition: opacity 0.6s var(--curve);
        will-change: opacity;
      `}
    >
      <div
        className="type--paragraph"
        css={css`
          width: 100%;
          display: flex;
          padding: 1.6rem var(--gutterHalf) 1.2rem;

          @media (max-width: 550px) {
            padding: 1.2rem (--gutterHalf) 1rem;
          }
        `}
      >
        {children}
      </div>
      <ProgressBar barColor={barColor} barBackground={barBackground} currentProgress={progress} />
    </div>
  );
}
