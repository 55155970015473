import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import { Back, Info, Save } from './icons';
import ProgressBarWrapper from './progress-bar-wrapper';
import PopupHeadingInfoList from './popup-heading-info-list';

export default function ProgressBarProject({
  className,
  backButtonURL,
  text,
  info,
  saved,
  saveId,
  progressRef,
  barColor,
  barBackground
}) {
  const [displayInfoPopup, setDisplayInfoPopup] = useState(false);

  const theme = useContext(ThemeContext);

  return (
    <ProgressBarWrapper
      className={className}
      barColor={barColor}
      barBackground={barBackground}
      progressRef={progressRef}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          flex-basis: 100%;
          margin-top: -0.3rem;
        `}
      >
        <Back
          css={css`
            margin-right: var(--gutterHalf);

            @media (max-width: 800px) {
              margin-right: auto;
            }
          `}
          url={backButtonURL}
          hover={true}
        />
        <div
          className="type--heading-five"
          css={css`
            flex-grow: 1;
            margin-right: var(--gutter);
            margin-top: 0.4rem;

            @media (max-width: 800px) {
              display: none;
            }
          `}
        >
          {text}
        </div>
        {info && (
          <Info
            css={css`
              margin-right: 2rem;
            `}
            active={displayInfoPopup}
            onClick={() => setDisplayInfoPopup(!displayInfoPopup)}
          />
        )}
        <Save
          active={theme.collection.has(saveId)}
          onClick={(e) => {
            e.preventDefault();
            if (theme.collection.has(saveId)) {
              const newCollection = theme.collection;
              newCollection.delete(saveId);

              theme.setCollection(new Set(newCollection));
            } else {
              theme.setCollection(new Set([...theme.collection, saveId]));
            }
          }}
        />
        {info && (
          <div
            css={css`
              position: absolute;
              top: calc(100% + var(--gutter) + 1.2rem);
              right: var(--gutterHalf);
              display: ${displayInfoPopup ? 'block' : 'none'};
              width: 38.7rem;

              @media (max-width: 800px) {
                right: 0;
                max-width: 100%;
              }
            `}
          >
            <PopupHeadingInfoList invert={true} monoText="Specifications" items={info} />
          </div>
        )}
      </div>
    </ProgressBarWrapper>
  );
}
