import React, { useState } from 'react';
import { css } from '@emotion/react';
import BlockContent from './block-content';
import Grid from './grid';
import Image from './image';
import CaptionCardWrapper from './caption-card-wrapper';
import CaptionCard from './caption-card';
import Video from './video';
import Box from './box';

function ContentBox({ className, children, fullBleed, half }) {
  return (
    <div
      className={className}
      css={css`
        flex-basis: ${half ? '50%' : '100%'};
        padding: 0 var(--gutterHalf) var(--margin14);

        @media (max-width: 1024px) {
          padding: 0 var(--margin1) var(--margin10);
        }

        @media (max-width: 800px) {
          padding: 0 var(--margin1) var(--margin6);
        }

        @media (max-width: 550px) {
          flex-basis: 100%;
          padding: 0 0 var(--margin6);
        }

        > div {
          margin: ${fullBleed ? '0 calc(-1 * var(--margin))' : '0'};

          @media (max-width: 1024px) {
            margin: ${fullBleed ? '0 calc(-1 * var(--gutter))' : '0'};
          }

          @media (max-width: 550px) {
            margin: ${fullBleed ? '0 calc(-1 * var(--gutterHalf))' : '0'};
          }
        }
      `}
    >
      {children}
    </div>
  );
}

export default function ProjectImageView({ className, blocks, items }) {
  const [showProcess, setShowProcess] = useState(false);

  return (
    <div>
      <div
        className={className}
        css={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 0 calc(var(--margin) - var(--gutterHalf));

          @media (max-width: 1024px) {
            padding: 0 calc(var(--gutter) - var(--margin1));
          }

          @media (max-width: 550px) {
            padding: 0 var(--gutterHalf);
          }
        `}
      >
        {blocks.length > 0 &&
          blocks.map((block, i) => {
            switch (block?._type) {
              case 'richImage':
                return (
                  <ContentBox
                    key={i}
                    half={block.width === 2 ? true : false}
                    fullBleed={block.width === 1 ? true : false}
                  >
                    {block.image && (
                      <CaptionCardWrapper>
                        <Image
                          image={block.image}
                          captionIndent={block.width === 1 ? true : false}
                          hotspots={
                            block.hotSpots?.hasHotSpots &&
                            block.hotSpots?.hotSpots.map((hotspot) => {
                              return {
                                x: hotspot.x,
                                y: hotspot.y,
                                images: hotspot.images,
                                text: hotspot._rawText,
                              };
                            })
                          }
                        />
                        {block.captionCard?.hasCard && (
                          <CaptionCard
                            text={block.captionCard._rawCaption}
                            linkText={
                              block.captionCard.linkText ? block.captionCard.linkText : null
                            }
                            url={block.captionCard.linkURL ? block.captionCard.linkURL : null}
                            position={block.captionCard.position}
                            indent={block.width === 1 ? 0 : block.width === 2 ? 2 : 1}
                            css={css`
                              @media (max-width: 550px) {
                                width: ${block.width === 1
                                  ? 'calc(100% - var(--gutter))'
                                  : '21.7rem'};
                                margin-right: ${block.width === 1 ? 'var(--gutterHalf)' : '0'};
                                margin-left: ${block.width === 1 ? 'var(--gutterHalf)' : '0'};
                              }
                            `}
                          />
                        )}
                      </CaptionCardWrapper>
                    )}
                  </ContentBox>
                );
              case 'video':
                return (
                  <ContentBox key={i} fullBleed={block.width === 1 ? true : false}>
                    <Video
                      src={block.video.asset.url}
                      captionText={block.description}
                      captionIndent={block.width === 1 ? true : false}
                      muted={block.muted}
                    />
                  </ContentBox>
                );
              case 'tripleImage':
                return (
                  <div key={i}>
                    <ContentBox half={true}>
                      <Image image={block.portraitImage} />
                    </ContentBox>
                    <ContentBox>
                      <Image image={block.topLandscapeImage} />
                    </ContentBox>
                    <ContentBox>
                      <Image image={block.bottomLandscapeImage} />
                    </ContentBox>
                  </div>
                );
              case 'gallery':
                return (
                  <div key={i}>
                    {block.media.length > 0 &&
                      block.media.map((item, i) => (
                        <div key={i}>
                          {item._type === 'image' && (
                            <ContentBox
                              half={item.asset.metadata.dimensions.aspectRatio < 1 ? true : false}
                            >
                              <Image image={item} />
                            </ContentBox>
                          )}
                          {item._type === 'video' && (
                            <ContentBox>
                              <Video src={item.video.asset.url} muted={block.muted} />
                            </ContentBox>
                          )}
                        </div>
                      ))}
                  </div>
                );
              default:
                return null;
            }
          })}
      </div>
      {items.length > 0 && (
        <div>
          <Box
            x={10}
            y={12}
            css={css`
              h3 {
                display: inline-block;
                cursor: pointer;
              }
            `}
          >
            {!showProcess && (
              <h3
                className="type--underline type--underline-hover-state"
                onClick={() => setShowProcess(true)}
              >
                Show Process
              </h3>
            )}
            {showProcess && (
              <h3
                className="type--underline type--underline-hover-state"
                onClick={() => setShowProcess(false)}
              >
                Hide Process
              </h3>
            )}
          </Box>
          {showProcess && (
            <Box x={10} y={12}>
              <Grid
                css={css`
                  row-gap: var(--margin7);

                  @media (max-width: 1024px) {
                    row-gap: var(--margin5);
                  }

                  @media (max-width: 550px) {
                    row-gap: var(--gutter);
                  }

                  > div {
                    grid-column-start: span 6;

                    @media (max-width: 550px) {
                      grid-column-start: span 8;
                    }
                  }
                `}
              >
                {items.map((item, i) => (
                  <div key={i}>
                    {item.assetType === 'video' && <Video src={item.src} />}
                    {item.assetType === 'image' && (
                      <Image image={item.image} width={990} height={990} />
                    )}
                    {item.captionTitle && (
                      <div
                        className="type--body-small type--underline"
                        css={css`
                          display: inline-block;
                          margin-top: 2rem;
                          margin-bottom: 0.2rem;

                          @media (max-width: 550px) {
                            margin-top: 1.7rem;
                          }
                        `}
                      >
                        {item.captionTitle}
                      </div>
                    )}
                    {item.captionText && (
                      <div>
                        <BlockContent className="type--body-small" content={item.captionText} />
                      </div>
                    )}
                  </div>
                ))}
              </Grid>
            </Box>
          )}
        </div>
      )}
    </div>
  );
}
