import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function PopupHeadingInfoList({ className, invert, monoText, items }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <div
      className={className}
      css={css`
        width: 100%;
        background-color: ${white};
        padding: 2.2rem 3rem 3.4rem;
        border: 4px solid var(--black);

        @media (max-width: 550px) {
          padding: var(--gutter) var(--margin3);
        }
      `}
    >
      <div
        className="type--mono-heading"
        css={css`
          display: inline-block;
          color: ${black};
          padding-bottom: 0.4rem;
          border-bottom: 1px solid ${black};
          margin-bottom: 3rem;
        `}
      >
        {monoText}
      </div>
      <ul
        css={css`
          list-style: none;
          padding: 0;
          margin: 0;

          li:last-of-type div {
            margin-bottom: 0;
          }
        `}
      >
        {items &&
          items.map((item) => {
            return (
              <li key={item.title}>
                {item.text && (
                  <div
                    css={css`
                      color: ${black};
                      margin-bottom: 3rem;
                    `}
                  >
                    <h5
                      className="type--mono"
                      css={css`
                        margin-bottom: 0.7rem;
                      `}
                    >
                      {item.title}
                    </h5>
                    <div className="type--mono-heading">{item.text}</div>
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
