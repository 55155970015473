import React, { useState } from 'react';
import { css } from '@emotion/react';
import BlockContent from './block-content';

export default function Credits({
  className,
  bvn,
  collaborators,
  consultants,
  photography,
  video,
  awards,
}) {
  return (
    <div
      className={className}
      css={css`
        background-color: var(--gray-light);
      `}
    >
      <h3
        css={css`
          padding-bottom: var(--margin5);
          border-bottom: 4px solid var(--black);

          @media (max-width: 800px) {
            padding-bottom: 1.5rem;
          }
        `}
      >
        Credits
      </h3>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          column-gap: var(--gutter);
          padding-top: var(--margin7);

          @media (max-width: 800px) {
            column-gap: var(--gutterHalf);
            padding-top: var(--margin5);
          }

          @media (max-width: 650px) {
            grid-template-columns: repeat(8, 1fr);
            column-gap: var(--margin1);
          }

          > div {
            grid-column-start: span 4;

            @media (max-width: 1024px) {
              grid-column-start: span 6;
            }

            @media (max-width: 650px) {
              grid-column-start: span 8;
            }
          }

          h5 {
            margin-bottom: 3.3rem;

            @media (max-width: 800px) {
              margin-bottom: var(--margin3);
            }
          }
        `}
      >
        <div
          css={css`
            > div {
              margin-bottom: var(--gutter);

              @media (max-width: 800px) {
                margin-bottom: var(--margin3);
              }

              @media (max-width: 650px) {
                margin-bottom: var(--margin5);
              }

              &:last-child {
                margin-bottom: 0;

                @media (max-width: 650px) {
                  margin-bottom: var(--margin5);
                }
              }
            }
          `}
        >
          {bvn && (
            <div>
              <h5 className="type--underline">BVN</h5>
              <div className="type--body-small">{bvn}</div>
            </div>
          )}
          <div
            css={css`
              display: none;

              @media (max-width: 1024px) {
                display: block;
              }
            `}
          >
            {(collaborators || consultants) && (
              <div
                css={css`
                  > div {
                    margin-bottom: var(--gutter);

                    @media (max-width: 800px) {
                      margin-bottom: var(--margin3);
                    }

                    @media (max-width: 650px) {
                      margin-bottom: var(--margin5);
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                `}
              >
                {collaborators && (
                  <div>
                    <h5 className="type--underline">Collaborators</h5>
                    <BlockContent className="type--body-small" content={collaborators} />
                  </div>
                )}
                {consultants && (
                  <div>
                    <h5 className="type--underline">Consultants</h5>
                    <BlockContent className="type--body-small" content={consultants} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          css={css`
            @media (max-width: 1024px) {
              display: none;
            }
          `}
        >
          {(collaborators || consultants) && (
            <div
              css={css`
                > div {
                  margin-bottom: var(--gutter);

                  @media (max-width: 800px) {
                    margin-bottom: var(--margin3);
                  }

                  @media (max-width: 650px) {
                    margin-bottom: var(--margin5);
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              `}
            >
              {collaborators && (
                <div>
                  <h5 className="type--underline">Collaborators</h5>
                  <BlockContent className="type--body-small" content={collaborators} />
                </div>
              )}
              {consultants && (
                <div>
                  <h5 className="type--underline">Consultants</h5>
                  <BlockContent className="type--body-small" content={consultants} />
                </div>
              )}
            </div>
          )}
        </div>
        {(photography || video || awards) && (
          <div
            css={css`
              @media (max-width: 800px) {
                padding-left: 2.5rem;
              }

              @media (max-width: 650px) {
                padding-left: 0;
              }

              > div {
                margin-bottom: var(--gutter);

                @media (max-width: 800px) {
                  margin-bottom: var(--margin3);
                }

                @media (max-width: 650px) {
                  margin-bottom: var(--margin5);
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            `}
          >
            {photography && (
              <div>
                <h5 className="type--underline">Photography</h5>
                <BlockContent className="type--body-small" content={photography} />
              </div>
            )}
            {video && (
              <div>
                <h5 className="type--underline">Video</h5>
                <BlockContent className="type--body-small" content={video} />
              </div>
            )}
            {awards && (
              <div>
                <h5 className="type--underline">Awards</h5>
                <BlockContent className="type--body-small" content={awards} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
